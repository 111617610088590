import React, { useEffect, useState } from "react";
import classes from "./SwapEvm.module.css";
import {
  useModalByName,
  Processing,
  SettingsIcon,
  ButtonIcon,
  TokenPanel,
  TokensPanel,
} from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { SelectTokenModal, useAllowance } from "../../../../Tokens";
import { Settings } from "../Settings";
import { useEvmTokensPanel } from "../../../../TokensPanel";
import { TransactionModal } from "../TransactionModal";
import { SwapInfoCard } from "../SwapInfoCard";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../../Network";
import { useSettings } from "../../hooks/useSettings";
import { useTrade } from "../../hooks/useTrade";
import { Trade } from "@uniswap/sdk";
import { isWrappingTokens } from "../../../../../common/_evm/libs/isWrappingTokens";
import { useTokenListContext } from "../../../../../contexts/TokenList";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import Skeleton from "react-loading-skeleton";
import { formatUnits } from "ethers";
import { useTokenBalances } from "../../../../Tokens/_evm/hooks/useTokenBalances";

export const SwapEvm = () => {
  const { tokenList } = useTokenListContext();
  const { modal, closeModal, changeModal } = useModalByName();
  const { walletProvider } = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();

  const [openSettings, setOpenSettings] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [inputFrom, setInputFrom] = useState("");
  const { core } = useNetworkContext();

  const {
    tokens,
    setTokens,
    selectToken,
    token0Balance,
    token1Balance,
    error,
  } = useEvmTokensPanel(address, modal, inputFrom, tokenList);

  const { settings, uniswapSettings, setSettings } = useSettings();
  const isWrapping =
    !!tokens[0] && !!tokens[1] && isWrappingTokens(tokens[0], tokens[1]);

  const { trade, resError, loading } = useTrade(
    inputFrom,
    tokens[0],
    tokens[1],
    isWrapping,
  );

  const { approve, isAllowance, isApproving } = useAllowance(
    inputFrom,
    core?.router,
    tokens[0],
    setIsPending,
  );

  const balances = useTokenBalances(tokenList, core?.multicall);

  const [tradeConfirmed, setTradeConfirmed] = useState<Trade | undefined>();

  useEffect(() => {
    if (isAllowance) {
      setIsPending(false);
    }
  }, [isAllowance]);

  useEffect(() => {
    console.log(balances, "balances");
  }, [balances]);

  const setMax = () => {
    const maxBalance = formatUnits(token0Balance, tokens[0]?.decimals);
    setInputFrom(maxBalance);
  };

  return (
    <div className={classNames(classes.Wrapper, "card")}>
      <div className={classNames(classes.Headline, "space-between")}>
        <p className="p1 semi">Swap</p>
        <ButtonIcon onClick={() => setOpenSettings(!openSettings)}>
          <SettingsIcon />
        </ButtonIcon>
      </div>
      <TokensPanel setTokens={() => setTokens([tokens[1], tokens[0]])}>
        <TokenPanel
          token0={tokens[0]}
          title="From"
          tokenBalance={getShowBalance(token0Balance, tokens[0]?.decimals)}
          setValue={setInputFrom}
          value={inputFrom}
          tokenSelectHandle={() => changeModal("token-from")}
          setAmount={setMax}
        />
        <TokenPanel
          token0={tokens[1]}
          title="To"
          tokenBalance={getShowBalance(token1Balance, tokens[1]?.decimals)}
          value={
            isWrapping
              ? inputFrom
              : (trade?.outputAmount?.toSignificant(6) ?? "")
          }
          tokenSelectHandle={() => changeModal("token-to")}
        />
      </TokensPanel>
      {loading ? (
        <div style={{ width: "100%" }}>
          <Skeleton count={4} />
        </div>
      ) : (
        (trade !== undefined ||
          tokens[0]?.symbol === "JSOL" ||
          tokens[1]?.symbol === "JSOL") && (
          <SwapInfoCard
            uniswapSettings={uniswapSettings}
            tokens={tokens}
            trade={trade}
          />
        )
      )}
      {address ? (
        <button
          className={"btn block"}
          onClick={() => {
            if (!isAllowance) {
              approve(inputFrom);
            } else {
              setTradeConfirmed(trade);
              changeModal("swap");
            }
          }}
          disabled={
            !!error ||
            !!resError ||
            modal === "swap" ||
            isApproving ||
            isPending
          }
        >
          {error ??
            resError ??
            (isApproving ? (
              "Approving..."
            ) : modal === "swap" || isPending ? (
              <Processing />
            ) : !isAllowance ? (
              `Approve ${tokens[0]?.symbol}`
            ) : isWrapping ? (
              tokens[0]?.isNative ? (
                "Wrap"
              ) : (
                "Unwrap"
              )
            ) : (
              `Swap ${settings.expertMode ? "anyway" : ""}`
            ))}
        </button>
      ) : (
        <button
          className={"btn block"}
          onClick={() => open()}
          disabled={error === "Coming soon"}
        >
          {error === "Coming soon" ? "Coming soon" : "Connect wallet"}
        </button>
      )}
      {(modal === "token-to" || modal === "token-from") && (
        <SelectTokenModal
          toggleModal={closeModal}
          selectToken={selectToken}
          token={modal === "token-to" ? tokens[1] : tokens[0]}
          balances={balances.balancesList}
        />
      )}
      {openSettings ? (
        <div className={classes.Settings}>
          <Settings settings={settings} setSettings={setSettings} />
        </div>
      ) : null}
      {modal === "swap" &&
        tradeConfirmed &&
        address &&
        walletProvider &&
        tokens[0] &&
        tokens[1] && (
          <TransactionModal
            inputFrom={inputFrom}
            token0={tokens[0]}
            token1={tokens[1]}
            trade={tradeConfirmed}
            uniswapSettings={uniswapSettings}
            onCloseButtonClick={() => closeModal()}
            walletProvider={walletProvider}
            account={address}
            tokens={tokens}
          />
        )}
    </div>
  );
};
