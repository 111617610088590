import { ExternalLinkIcon, InfoRow } from "@curiodao/capital-dex-ui-kit";
import React from "react";
import classes from "./JsolLinks.module.css";

export const JsolLinks = () => {
  return (
    <>
      <InfoRow
        title="Solana <> Ethereum bridge"
        info="JSOL is a token originally deployed on Solana and bridged to the TON network via Ethereum."
        value={
          <>
            <a
              href="https://portalbridge.com/advanced-tools/#/transfer"
              target="_blank"
              rel="noreferrer"
              className={classes.Link}
            >
              Use Portal
              <ExternalLinkIcon />
            </a>
          </>
        }
      />
      <InfoRow
        title="Ethereum <> TON bridge"
        info="JSOL is a token originally deployed on Solana and bridged to the TON network via Ethereum."
        value={
          <>
            <a
              href="https://bridge.ton.org/"
              target="_blank"
              rel="noreferrer"
              className={classes.Link}
            >
              Use TON bridge
              <ExternalLinkIcon />
            </a>
          </>
        }
      />
      <InfoRow
        title="JSOL on Ethereum"
        value={
          <>
            <a
              href="https://etherscan.io/token/0x445C4fc477469A218A5867B23231BC674B20C3c5"
              target="_blank"
              rel="noreferrer"
              className={classes.Link}
            >
              0x445C..C3c5
              <ExternalLinkIcon />
            </a>
          </>
        }
      />
      <InfoRow
        title="JSOL on TON"
        value={
          <>
            <a
              href="https://tonviewer.com/EQAQivJRFvXuJDGghheE9RX076HHjySz5-QuHZnff_jnXqb1"
              target="_blank"
              rel="noreferrer"
              className={classes.Link}
            >
              EQAQiv..Xqb1 <ExternalLinkIcon />
            </a>
          </>
        }
      />
    </>
  );
};
