import React from "react";
import { SwapIcon, InfoRow } from "@curiodao/capital-dex-ui-kit";
import { IToken } from "@curiodao/capital-dex-sdk";
import { ITrade } from "../../interfaces/ITrade";
import { JsolLinks } from "../../../../../components/JsolLinks";

export const SwapInfoCard = ({
  tokens,
  trade,
}: {
  tokens: (IToken | undefined)[];
  trade: ITrade | undefined;
}) => {
  return (
    <div className="card bordered">
      {trade && (
        <>
          <InfoRow
            title="Rates"
            value={
              <>
                1 {tokens[0]?.symbol} = {parseFloat(trade.swap_rate).toFixed(4)}{" "}
                {tokens[1]?.symbol} <SwapIcon />
              </>
            }
          />
          <InfoRow
            title="Minimum received"
            info="Your transaction will revert if there is a large, unfavorable price movement before it is confirmed."
            value={`${trade.min_ask_units} ${tokens[1]?.symbol}`}
          />
          <InfoRow
            title="Price impact"
            info="The difference between the market price and estimated price due to trade size."
            value={`${trade.price_impact}%`}
          />
          {/*<InfoRow*/}
          {/*  title="Liquidity provider fee"*/}
          {/*  info="A portion of each trade (0.30%) goes to liquidity providers as a protocol incentive."*/}
          {/*  value={`${trade.fee_percent} TON`}*/}
          {/*/>*/}
        </>
      )}
      {(tokens[0]?.symbol === "JSOL" || tokens[1]?.symbol === "JSOL") && (
        <JsolLinks />
      )}
    </div>
  );
};
